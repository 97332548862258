<template>
  <div class="work">
    <div class="container is-max-desktop">
      <div class="py-6">
        <p class="title is-1 is-size-3-mobile has-text-centered has-text-black">
          HelloChat App V2 Redesign
        </p>
        <nav
          class="breadcrumb is-small is-centered"
          aria-label="breadcrumbs"
        >
          <ul>
            <li><a @click="$router.push('/')">features</a></li>
            <!-- <li><a href="/work">work</a></li> -->
            <li class="is-active">
              <a href="#" aria-current="page">HelloChat</a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- <div class="markdown-body">
        <Demo />
      </div> -->
    </div>

    <!-- Cover Image -->
    <div class="container is-max-widescreen">
      <figure class="image">
        <img src="img/works/090/cover-2.jpg" />
      </figure>
    </div>

    <div class="my-6" />

    <!-- Product Overview -->
    <div class="container is-fluid py-6">
      <div class="container is-max-widescreen">
        <p class="title is-2 is-size-3-mobile has-text-weight-bold has-text-black">
          Project Overview
        </p>
        <hr />
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Project Goal
              </p>
              <div class="content">
                <ol>
                  <li>
                    Redesign HelloChat UI according to the componentized design
                    mode, and build a complete set of UI design system and a
                    front-end component library to fit both dev and design
                    team's collaboration on the project at the same time
                  </li>
                  <li>
                    Optimize existing interaction design based on user feedbacks
                  </li>
                  <li>
                    Design new product features based on user feedbacks and
                    product feature planning
                  </li>
                </ol>
              </div>
            </article>
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Responsibility
              </p>
              <div class="content">
                <p>
                  Lead on improving the product based on user feedback and new
                  product requirements to achieve a new user-centered design.
                  Review and improve the previous user flows, wireframes, low-
                  and high-fidelity prototypes, usability testing, and
                  research-based on new requirements. Cooperate with the
                  development team to rebuild various types of user interface
                  components using componentized design systems and patterns
                </p>
              </div>
            </article>
          </div>
          <div class="tile is-vertical">
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Product Description
                  </p>
                  <div class="content">
                    <p>
                      HelloChat is a Social & Payment Platform app. And provide
                      users with convenient, safe and fast new payment methods
                      services to make users' life experience better.
                    </p>
                  </div>
                </article>
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Role
                  </p>
                  <div class="content">
                    <p>UI/UX designer</p>
                  </div>
                </article>
              </div>
              <div class="tile is-parent is-vertical">
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Duration
                  </p>
                  <div class="content">
                    <p>1 Month</p>
                  </div>
                </article>
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Tools
                  </p>
                  <div class="content">
                    <p>Figma, XD, Mockingbot</p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-6" />

    <!-- Understanding Users -->
    <div class="container is-fluid py-6">
      <div class="container is-max-widescreen">
        <p class="title is-2 is-size-3-mobile has-text-weight-bold has-text-black">
          Understanding Problems
        </p>
        <hr />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                Existing Problems
              </p>
            </article>
          </div>
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Pain Point
              </p>
              <div class="content">
                <p>
                  The HelloChat v1 has many problems as follows base on the user
                  feedbacks,
                </p>
                <ul class="skill">
                  <li>Low user engagement</li>
                  <li>Hight interaction cost</li>
                  <li>Unsightly UI, inconsistent layout and design elements</li>
                  <li>Complex app user flow</li>
                  <li>Insufficient product stability</li>
                </ul>
              </div>
              <div class="my-6" />
              <!-- <figure class="image">
                <img src="img/works/091/keywords.jpg" />
              </figure> -->
            </article>
          </div>
        </div>

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                Summary Improvements
              </p>
            </article>
          </div>
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <div class="content">
                <p>
                  HelloChat is a mobile APP that integrates instant messaging
                  and mobile payment as the main functions.
                </p>
                <p>
                  The instant messaging part is a widely used type. Users
                  already have a certain understanding and habit of using this
                  type of APP. Try to use the interactive form of existing
                  social APP to reduce the user's learning cost, and then carry
                  out some systems in the details. optimization.
                </p>
                <p>Regarding the mobile payment section,</p>
                <ul>
                  <li>
                    It is necessary to reduce the cost of interactive use by
                    introducing the necessary guide pages
                  </li>
                  <li>Provides easy editing options</li>
                  <li>
                    Provide accurate and real-time user operation feedback to
                    ensure that users understand their operations; dispel users'
                    doubts about use
                  </li>
                </ul>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>

    <!-- Design -->
    <div class="container is-fluid py-6">
      <div class="container is-max-widescreen">
        <p class="title is-2 is-size-3-mobile has-text-weight-bold has-text-black">
          Improvements
        </p>
        <hr />
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                Design System optimization
              </p>
            </article>
          </div>
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Adopt componentized design
              </p>
              <div class="content">
                <p>
                  Componentized design can produce inherent
                  efficiencies for designers and developers, and solve the
                  challenges associated with duplication and inconsistency ,
                  while driving the development of better quality,
                  reusable, maintainable components.
                </p>
              </div>
              <!-- <figure class="image" @click="handleOpenActiveLightBox('https://dummyimage.com/16:9x1080/f8f8f8/b0b0b0')">
                <img src="https://dummyimage.com/16:9x1080/f8f8f8/b0b0b0" />
              </figure>
              <p class="subtitle is-7 has-text-centered py-3">Click on image to enlarge</p> -->
            </article>
            <article class="tile is-child">
              <p class="py-3 is-size-6 has-text-weight-bold ">
                Advantages of componentization for design
              </p>
              <figure
                class="image"
                @click="
                  handleOpenActiveLightBox(
                    'img/works/090/advantages-design.jpg'
                  )
                "
              >
                <img src="img/works/090/advantages-design.jpg" />
              </figure>
            </article>

            <article class="tile is-child">
              <p class="py-3 is-size-6 has-text-weight-bold ">
                Advantages of componentization for development
              </p>
              <figure
                class="image"
                @click="
                  handleOpenActiveLightBox('img/works/090/advantages-dev.jpg')
                "
              >
                <img src="img/works/090/advantages-dev.jpg" />
              </figure>
            </article>
          </div>
        </div>

        <div class="tile is-ancestor"></div>

        <div class="my-6" />

        <!-- <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                Usability Studies
              </p>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="
                  handleOpenActiveLightBox('img/works/091/affinity-diagram.jpg')
                "
              >
                <img src="img/works/091/affinity-diagram.jpg" />
              </figure>
              <p class="subtitle is-7 has-text-centered py-3">
                Click on image to enlarge
              </p>
            </article>
          </div>
        </div> -->

        <div class="my-6" />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                User Interface Design Improvement
              </p>
            </article>

            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/090/ui-1.jpg')"
              >
                <img src="img/works/090/ui-1.jpg" />
              </figure>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/090/ui-2.jpg')"
              >
                <img src="img/works/090/ui-2.jpg" />
              </figure>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/090/ui-3.jpg')"
              >
                <img src="img/works/090/ui-3.jpg" />
              </figure>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/090/ui-4.jpg')"
              >
                <img src="img/works/090/ui-4.jpg" />
              </figure>
              <!-- <p class="subtitle is-7 has-text-centered py-3">
                Click on image to enlarge
              </p> -->
            </article>
          </div>
        </div>
      </div>
    </div>

    <!-- Image light box modal -->
    <div class="modal" :class="{ 'is-active': isLightBoxActive }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <figure class="image">
          <img :src="lightBoxImageSrc" />
        </figure>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="handleCloseActiveLightBox"
      ></button>
    </div>

    <!-- Back to top -->
    <back-to-top bottom="50px" right="50px">
      <button class="button is-normal is-responsive">
        <span class="icon has-text-weight-bold has-text-black">UP</span>
      </button>
    </back-to-top>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
// import VueMarkdown from 'vue-markdown-render';
// import Demo from "./demo.md";

// 代码高亮
// import "highlight.js/styles/github.css";
// 其他元素使用 github 的样式
// import "github-markdown-css";

export default {
  name: "work",
  components: {
    Navbar,
  },
  data() {
    return {
      isLightBoxActive: false,
      lightBoxImageSrc: "",
    };
  },
  mounted() {
    this.track();
  },
  methods: {
    handleOpenActiveLightBox(src) {
      this.lightBoxImageSrc = src;
      this.isLightBoxActive = true;
    },
    handleCloseActiveLightBox() {
      this.lightBoxImageSrc = "";
      this.isLightBoxActive = false;
    },
    toIndexList() {
      // this.$router.push('/');
      // this.$router.reload();
      window.location.reload();
    },
    track() {
      this.$gtag.pageview("/hellochat");
      this.$gtag.query('event', 'screen_view',{
        app_name: 'juliandesign.io',
        screen_name: 'HelloChat',
      })
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 769px) {
  .modal-content,
  .modal-card {
    width: 80%;
  }
}
</style>
